<template>
  <v-container class="mt-6 px-0" fluid>
    <v-toolbar dark :color="color" class="mt-4" :class="filterSelected?'mb-0':'mb-3'"
    >
      <v-col cols="6" sm="6" md="6">
        <v-text-field
            v-model="pagination.search"
            clearable
            flat
            dense
            solo-inverted
            hide-details
            prepend-inner-icon="mdi-magnify"
            label="Search"
            placeholder="Search"
        ></v-text-field>
      </v-col>
      <v-col cols="3" class="text-left" md="3">
        <v-btn
            depressed
            outlined
            dark
            @click="filterSelected = !filterSelected"
        >
          <v-icon left>mdi-filter-variant</v-icon>
          {{ filterSelected ? 'Close Filters' : 'Filters' }}
        </v-btn>
      </v-col>
      <v-col cols="3" class="text-right" md="3">
        <v-btn
            depressed
            outlined
            dark
            :loading="exportLoading"
            @click="exportTableToExcel"
        >
          <v-icon left>mdi-download-outline</v-icon>
          Export
        </v-btn>
      </v-col>
    </v-toolbar>
    <filter-wrapper v-if="filterSelected" :filters.sync="filters"  color="light-blue" :model.sync="paginationFilter" :loading.sync="loading" v-on:applyFilters="applyFilters" v-on:resetFilters="resetFilters"></filter-wrapper>
    <v-data-table
        :headers="headers"
        :items="productivity"
        class="elevation-3"
        :options="pagination"
        :search="pagination.search"
        :items-per-page.sync="pagination.rowsPerPage"
        :server-items-length.sync="total"
        :page.sync="pagination.page"
        :footer-props="{
        showFirstLastPage: true,
        showCurrentPage: true,
        itemsPerPageOptions: itemsPerPageOptions
      }"
    >
      <template v-slot:item.for_month="{item}">
        {{ formDateName(item.productivity_report.for_month) }}
      </template>
      <template v-slot:item.first_contract_signed_at="{item}">
        {{ formatDate(item.first_contract_signed_at) }}
      </template>
      <template v-slot:item.contract_updated_at="{item}">
        {{ formatDate(item.contract_updated_at) }}
      </template>
      <template v-slot:item.contract_expired="{item}">
        {{ formatDate(item.contract_expired) }}
      </template>
      <template v-slot:item.neto_salary="{item}">
        {{ item.neto_salary.toFixed(2) }}
      </template>
      <template v-slot:item.gross_salary="{item}">
        {{ item.gross_salary.toFixed(2) }}
      </template>
      <template v-slot:item.total_cost="{item}">
        {{ item.total_cost.toFixed(2) }}
      </template>
      <template v-slot:body.append v-if="$vuetify.breakpoint.smAndDown?false:true">
        <tr class="green--text">
          <th class="font-weight-bold">Totals:</th>
          <th></th>
          <th></th>
          <th></th>
          <th></th>
          <th></th>
          <th class="text-center">{{ sumValues('vacation_days') }}</th>
          <th class="text-center">{{ sumValues('vacation_days_used') }}</th>
          <th class="text-center">{{ sumValues('vacation_days_left') }}</th>
          <th class="text-center">{{ sumValues('sick_leave') }}</th>
          <th class="text-center">{{ sumValues('overtime_hours') }}</th>
          <th class="text-right">{{ sumValues('neto_salary').toFixed(2) }}</th>
          <th class="text-right">{{ sumValues('gross_salary').toFixed(2) }}</th>
          <th class="text-right">{{ sumValues('total_cost').toFixed(2) }}</th>
        </tr>
      </template>

    </v-data-table>
  </v-container>
</template>
<script>
import permission from "@/mixins/permission";
import date from "@/mixins/date";
import Vue from "vue";
import downloadExcel from "@/mixins/downloadExcel";
import {mapGetters} from "vuex";
export default {
  components: {
    FilterWrapper: () => import("@/components/core/filters/FilterWrapper"),
  },
  Name: "generalTab",
  mixins: [permission, date, downloadExcel],
  data() {
    return {
      itemsPerPageOptions: [12, 24, 48, -1],
      paginationFilter: {},
      filterSelected: false,
      exportLoading: false,
      headers: [
        {
          text: "Month",
          value: "productivity_report.for_month",
          sortable: true,
          width: "140px",
          align: "start"
        }, {
          text: "Job Location",
          value: "job_location",
          width: "150px"
        }, {
          text: "Job Name",
          value: "job_position_name",
          width: "150px"
        }, {
          text: "Contract Signed In",
          value: "first_contract_signed_at",
          width: "170px",
          align: "center"
        }, {
          text: "Contract Updated At",
          value: "contract_updated_at",
          width: "170px",
          align: "center"
        }, {
          text: "Contract Expire",
          value: "contract_expired",
          width: "150px",
          align: "center"
        }, {
          text: "Vacation Days",
          value: "vacation_days",
          width: "160px",
          align: "center"
        }, {
          text: "Vacation Days Used",
          value: "vacation_days_used",
          width: "170px",
          align: "center"
        }, {
          text: "Vacation Days Left",
          value: "vacation_days_left",
          width: "160px",
          align: "center"
        }, {
          text: "Sick leave hours",
          value: "sick_leave",
          width: "160px",
          align: "center"
        }, {
          text: "Overtime hours",
          value: "overtime_hours",
          width: "160px",
          align: "center"
        }, {
          text: "Neto Salary",
          value: "neto_salary",
          width: "150px",
          align: "end"
        }, {
          text: "Gross Salary",
          value: "gross_salary",
          width: "150px",
          align: "end"

        }, {
          text: "Total Cost",
          value: "total_cost",
          width: "120px",
          align: "end"

        },
      ],
    };
  },
  props: {
    item: {
      required: true,
      type: Object
    },
    color: {
      required: true,
      type: String
    }
  },
  computed: {
    ...mapGetters({
      filters: "employees/filtersShowPage",
      productivity: "employees/productivityDetails",
      total: "employees/totalDetails",
      loading: "employees/loading",
    }),
    pagination: {
      get() {
        return this.$store.getters["employees/pagination"];
      },
      set(value) {
        this.$store.dispatch("employees/setPagination", value);
      }
    },
  },
  watch: {
    pagination: {
      handler() {
        this.getProductivityData();
      },
      deep: true
    }
  },
  created() {
    this.getProductivityData()
  },
  methods: {
    async getProductivityData() {
      await this.$store.dispatch("employees/getProductivityDetails", this.item.psit_id)
    },
    sumValues(key) {
      const sumValues = this.productivity.reduce((a, b) => a + (b[key] || 0), 0)
      return sumValues
    },
    resetFilters() {
        Vue.set(this.paginationFilter, 'jobLocations', [])
        Vue.set(this.paginationFilter, 'jobPositionNames', [])
        Vue.set(this.paginationFilter, 'salaryType', null)
        Vue.set(this.paginationFilter, 'salaryFrom', null)
        Vue.set(this.paginationFilter, 'salaryTo', null)
        Vue.set(this.paginationFilter, 'contractLength', null)
        Vue.set(this.paginationFilter, 'vacationType', null)
        Vue.set(this.paginationFilter, 'vacationFrom', null)
        Vue.set(this.paginationFilter, 'vacationTo', null)
        Vue.set(this.paginationFilter, 'sickLeaveFrom', null)
        Vue.set(this.paginationFilter, 'sickLeaveTo', null)
        Vue.set(this.paginationFilter, 'overtimeHoursFrom', null)
        Vue.set(this.paginationFilter, 'overtimeHoursTo', null)
        this.pagination = {
            ...this.pagination,
            ...this.paginationFilter
        };
    },
    applyFilters() {
    this.pagination = {
        ...this.pagination,
        ...this.paginationFilter
    };
    },
    exportTableToExcel(){
      this.exportLoading = true;
      this.exportToExcel(this.headers, this.productivity, this.item.first_name +this.item.last_name+ '.xlsx');
      this.exportLoading = false
    }
  },

};
</script>
